/* You can add global styles to this file, and also import other style files */
@import "./vars.less";

html,
body {
  height: 100%;
  overflow: hidden;
}

body {
  margin: 0;
  font: @font-alt;
  color: @neutral800;
}

select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;

  background-repeat: no-repeat;
  background-size: 0.5em auto;
  background-position: right 0.25em center;
  padding-right: 1em;

  background-image: url("data:image/svg+xml;charset=utf-8, \
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 60 40'> \
      <polygon points='0,0 60,0 30,40' style='fill:black;'/> \
    </svg>");
}

.icon {
  font-weight: normal;
  font-style: normal;
  font-size: 16px;
  line-height: 1;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  direction: ltr;
  -webkit-font-feature-settings: 'liga';
  -webkit-font-smoothing: antialiased;
  background-repeat: no-repeat;
  display: inline-block;
  fill: currentColor;
  min-height: 16px;
  min-width: 16px;
}

.froala-icon {
  width: auto !important;
  font-size: 18px;
}

.fr-no-hover {
  color: @lightGrey !important;

  &:hover {
    background-color: inherit !important;
  }
}

.medium-icon {
  height: auto;
  width: auto;
  font-size: 14px;
}

.small-icon {
  height: auto;
  width: auto;
  font-size: 10px;
}

::-webkit-scrollbar {
  width: 4px;
  border-radius: 4px;
}

::-webkit-scrollbar-track {
  background: @lightestGrey;
}

::-webkit-scrollbar-thumb {
  background: @darkGrey;
}

::-webkit-scrollbar-thumb:hover {
  background: darken(@darkGrey, 10%);
}

.page-wrapper {
  flex: 1;
  box-sizing: border-box;
  max-height: 100%;
  max-width: 100%;
  overflow: hidden;
}

.page {
  height: 100%;
  max-height: 100%;
  overflow: hidden;
  position: relative;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
}

.header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  height: 40px;
  background-color: var(--accent-color);
  color: white;
}

.header-spacer {
  width: 30px;
}

.content {
  flex: 1;
  width: 100%;
  background-color: white;
  margin: 0 auto;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}


.title {
  flex: 1;
  margin: 0;
  font-size: 22px;
  font-weight: bold;
  padding-left: 10px;
}

.full-width {
  width: 100%;
}

.fab-icon {
  border-radius: 50%;
  height: 30px;
  width: 30px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;

  &:disabled {
    color: @grey;
  }

  &:hover {
    box-shadow: 0px 0px 4px @lighterGrey;
  }
}

.icon-border {
  border-width: 1px;
  border-color: inherit;
  border-style: solid;
  box-sizing: border-box;
}

.small-fab {
  height: 15px;
  width: 15px;
}

.icon-button {
  height: 40px;
  width: 40px;
  font-size: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: transparent;
  color: white;

  &:disabled {
    color: @grey;
  }

  &:hover {
    // color: darken(@darkGrey, 10%);
  }
}

.app-input {
  border: none;
  border: 1px solid @grey;
  padding: 8px;
  border-radius: 8px;
  background-color: inherit;
  box-sizing: border-box;

  &:focus {
    outline: none;
    border: 1px solid @lightBlue;
  }
}

.rotate-up {
  transform: rotate(-90deg);
}

.error {
  color: @red;

  &:disabled {
    color: @grey;
  }
}

.primary {
  color: @blue;

  &:disabled {
    color: @grey;
  }
}

.primary-background {
  background-color: @blue;
  color: white;

  &:disabled {
    background-color: @grey;
  }
}

.segments {
  display: flex;
  align-items: center;
  background-color: @lightGrey;
  color: @blue;
  border-radius: 30px;
  box-shadow: inset 0px 0px 8px @grey;
  margin: 5px;
}

.segment {
  flex: 1;
  text-align: center;
  font-weight: bold;
  padding: 5px;
  cursor: pointer;

}

.active-segment {
  background-color: @lightestGrey;
  color: black;
  box-shadow: 0 2px 4px @grey;
  border-radius: 30px;
}

.app-button {
  border: none;
  background-color: @blue;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px 10px;
  border-radius: 5px;
  cursor: pointer;

  &:disabled {
    background-color: @grey;
    color: white;
  }

  &.inverted {
    color: @blue;
    background-color: white;

    &.error {
      color: @red;
      background-color: white;
    }
  }

  &.error {
    background-color: @red;
    color: white;
  }
}

.header-button {
  font: @font;
  font-weight: normal;
  width: auto;
  border-radius: 5px;
  height: auto;
  padding: 3px 6px;
  margin-right: 5px;
  color: @blue;
  background-color: white;
}

.header-button-icon {
  margin-right: 5px;
}

.button-link {
  border: none;
  text-transform: uppercase;
  color: @blue;
  background-color: transparent;
  cursor: pointer;

  &:disabled {
    color: @grey;
  }
}

.froala-todo {
  font-style: italic;
  color: @blue;
}

.loader {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255, 255, 255, .3);
  border-radius: 50%;
  border-top-color: @blue;
  animation: spin 1s ease-in-out infinite;
  -webkit-animation: spin 1s ease-in-out infinite;
}

@keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes spin {
  to {
    -webkit-transform: rotate(360deg);
  }
}

.page-overlay {
  position: absolute;
  background-color: rgba(0, 0, 0, .1);
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  z-index: 3;
}


.overlay-pane {
  position: relative !important
}

.overlay {
  background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 4px @grey;
  padding: 20px;
  font: @font-alt;
  border: 1px solid @lightBlue;
  overflow: auto;
}


.overlay-close-button {
  position: absolute;
  right: -10px;
  top: -10px;
  height: 30px;
  width: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid @lightestGrey;

  &.left {
    right: auto;
    left: -10px
  }
}

.overlay-backdrop {
  background-color: rgba(0, 0, 0, .1)
}

.message-input-hint {
  text-align: right;
  font-size: 10px;
  line-height: 14px;
  color: @darkGrey;
}

.dashboard-sidebar {
  overflow: hidden;
  background-color: @lightestGrey;
  flex: 1;
  display: flex;
  flex-direction: column;
  // border-right: 1px solid @grey;
  box-shadow: 0 0 4px @lightGrey;
  z-index: 7;
  width: 350px;
}





//note: if these become to many move to separate file

.app-select {
  .mat-select-value-text {
    color: @blue;
  }

  .mat-select-arrow {
    color: @blue;
  }
}

.app-date-input {
  & .mat-form-field-flex {
    background-color: white !important;
    padding: 0 !important;
    border: none !important;
  }

  & .mat-form-field-infix {
    padding: 0 !important;
  }
}

.mat-slide-toggle.mat-checked .mat-ripple-element,
.mat-slide-toggle.mat-checked .mat-slide-toggle-thumb {
  background-color: white;
  border: 1px solid @lightGrey;
  box-sizing: border-box;
}

.mat-slide-toggle.mat-checked .mat-slide-toggle-bar {
  background-color: @green;
}

.mdc-evolution-chip-set {


  & .mat-mdc-standard-chip {
    &:not(.mdc-evolution-chip--disabled) {
      background-color: @lightBlue;
      margin: 3px;
      border-radius: 5px;
      height: auto;
      font: @font;
    }
  }

  & .mat-mdc-standard-chip.mdc-evolution-chip--with-trailing-action .mdc-evolution-chip__action--primary {
    padding-left: 5px;
    overflow: hidden;
  }

  & .mat-mdc-chip.mdc-evolution-chip--with-trailing-action .mat-mdc-chip-action-label {
    font: @font;
    overflow: hidden;
  }
}

.mat-mdc-menu-item.mdc-list-item {

  & .mdc-list-item__primary-text {
    font: @font;
  }
}

.mat-mdc-form-field {
  & .mat-mdc-form-field-focus-overlay {
    display: none;
  }

  &:hover,
  &.mat-focused {
    & .mat-mdc-form-field-flex {
      border-color: @neutral300;
    }
  }

  & .mat-mdc-form-field-flex {
    font: @font-alt;
    border: none;
    border: 1px solid @neutral100;
    padding: 0px 8px;
    border-radius: 4px;
    background-color: inherit;
    box-sizing: border-box;
    background-color: white;
    box-shadow: 0px 2px 2px 0px rgba(0, 0, 0, 0.12) inset;

    &:focus {
      outline: none;
      border: 1px solid @neutral300;
    }

    & .mat-mdc-form-field-wrapper {
      padding-bottom: 0;
    }

    & .mat-mdc-form-field-infix {
      padding: 3px !important;
      min-height: 0;
    }
  }

  & .mdc-line-ripple {
    display: none;
  }

  & .mdc-text-field {
    padding: 0;
  }

  & .mat-mdc-form-field-subscript-wrapper {
    display: none;
  }
}

.mat-mdc-form-field-wrapper {
  padding-bottom: 0 !important;
}

.mat-mdc-autocomplete-panel {
  --mat-optgroup-label-text-font: @font-alt-family;
  --mat-optgroup-label-text-color: @neutral700;
  --mat-optgroup-label-text-size: 12px;
  --mat-optgroup-label-text-weight: 700;
  --mat-option-label-text-color: @blue500;
  padding: 0 !important;

  .mat-mdc-optgroup-label {
    min-height: auto;
    padding: 8px;
    line-height: 12px;
    background-color: @neutral25;
    border-top: 1px solid @neutral200;
    border-bottom: 1px solid @neutral50;
  }

  .mat-mdc-optgroup .mat-mdc-option:not(.mat-mdc-option-multiple) {
    --mdc-theme-text-primary-on-background: @blue500;
    min-height: auto;
    padding: 12px 0;
    margin: 0 12px;
    border-bottom: 1px solid @neutral100;

    &:last-child {
      border-bottom: none;
    }
  }
}



.mat-form-field.mat-focused {
  .mat-form-field-flex {
    border: 1px solid @neutral300;
  }
}

.mat-focused .mat-form-field-label {
  color: @grey !important;
}

.mat-input-element {
  caret-color: initial;
}

.mat-form-field-infix {
  border-top: none;
}

.mat-form-field-underline {
  display: none;
}

.mat-chip {
  background-color: @lighterBlue !important;
  border-radius: 5px !important;
  width: auto !important;
  display: flex !important;
  align-items: center !important;
  font: @font-alt !important;
  padding: 0 3px 0 5px !important;
  min-height: auto !important;
  height: auto !important;

  &:hover {
    background-color: @lighterBlue;

    &::after {
      opacity: 0 !important;
    }
  }
}

.mat-chip-remove {
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 3px;
}

.empty-state {
  color: #787878;
  text-align: center;
  margin: 20px 0;
  font: @font;
  font-style: italic;
}

.chat-unread {
  font: @font-alt;
  display: flex;
  align-items: center;
  color: @grey;
  font-size: 12px;
  margin-right: 10px;

  &.chat-unread-black {
    color: black;

    & .chat-unread-icon {
      background-color: black;
    }
  }

  &.chat-unread-blue {
    color: @blue;

    & .chat-unread-icon {
      background-color: @blue;
    }
  }

  &.chat-unread-green {
    color: green;

    & .chat-unread-icon {
      background-color: green;
    }
  }
}

.mat-slide-toggle {
  &.mat-checked {
    & .mat-slide-toggle-bar {
      background-color: @green;
    }
  }

  & .mat-slide-toggle-content {
    color: @darkGrey;
  }
}

.mat-mdc-checkbox {
  --mat-option-selected-state-label-text-color: @blue500;
  --mdc-checkbox-state-layer-size: 20px;
  --mdc-checkbox-selected-icon-color: @blue500;
  --mdc-checkbox-selected-focus-icon-color: @blue500;
  --mdc-checkbox-selected-hover-icon-color: @blue500;
  --mdc-checkbox-selected-pressed-icon-color: @blue500;
  --mdc-checkbox-selected-focus-state-layer-color: @blue500;
  --mdc-checkbox-selected-hover-state-layer-color: @blue500;
  --mdc-checkbox-selected-pressed-state-layer-color: @blue500;
  --mdc-checkbox-unselected-focus-icon-color: @blue500;
  --mdc-checkbox-unselected-hover-icon-color: @blue500;
  --mdc-checkbox-unselected-icon-color: @blue500;
  --mdc-checkbox-unselected-pressed-icon-color: @blue500;
  --mdc-checkbox-unselected-focus-state-layer-color: @blue500;
  --mdc-checkbox-unselected-hover-state-layer-color: @blue500;
  --mdc-checkbox-unselected-pressed-state-layer-color: @blue500;
  --mdc-checkbox-selected-focus-state-layer-opacity: 0;
  --mdc-checkbox-unselected-focus-state-layer-opacity: 0;
  --mdc-checkbox-unselected-hover-state-layer-opacity: 0.08;

  .mat-mdc-checkbox-touch-target {
    height: auto;
    width: auto;
  }

  & .mdc-form-field {
    --mdc-typography-font-family: @font-alt-family;

    >label {
      padding-left: 8px;

      >i {
        font-size: 12px;
        color: @blue500;
      }
    }
  }
}

.mat-mdc-slide-toggle {
  --mdc-switch-disabled-selected-handle-color: @white;
  --mdc-switch-disabled-unselected-handle-color: @white;
  --mdc-switch-disabled-selected-track-color: @white;
  --mdc-switch-disabled-unselected-track-color: @white;
  --mdc-switch-unselected-focus-state-layer-color: @white;
  --mdc-switch-unselected-pressed-state-layer-color: @white;
  --mdc-switch-unselected-hover-state-layer-color: @white;
  --mdc-switch-unselected-focus-track-color: @neutral500;
  --mdc-switch-unselected-hover-track-color: @neutral500;
  --mdc-switch-unselected-pressed-track-color: @neutral500;
  --mdc-switch-unselected-track-color: @neutral500;
  --mdc-switch-unselected-focus-handle-color: @lightestGrey;
  --mdc-switch-unselected-hover-handle-color: @lightestGrey;
  --mdc-switch-unselected-pressed-handle-color: @lightestGrey;
  --mdc-switch-handle-surface-color: var(--mdc-theme-surface, #fff);
  --mdc-switch-unselected-handle-color: white;
  --mdc-switch-selected-icon-color: white;
  --mdc-switch-disabled-selected-icon-color: white;
  --mdc-switch-disabled-unselected-icon-color: white;
  --mdc-switch-unselected-icon-color: white;
  --mdc-switch-handle-width: 10px;
  --mdc-switch-handle-height: 10px;
  --mdc-switch-track-height: 14px;
  --mdc-switch-track-width: 20px;
  --mdc-switch-track-shape: 14px;
  --mdc-switch-state-layer-size: 20px;
  --mdc-switch-handle-elevation: 0;
}

.mat-mdc-slide-toggle.mat-accent {
  --mdc-switch-selected-focus-state-layer-color: white;
  --mdc-switch-selected-handle-color: white;
  --mdc-switch-selected-hover-state-layer-color: white;
  --mdc-switch-selected-pressed-state-layer-color: white;
  --mdc-switch-selected-focus-handle-color: @lightestGrey;
  --mdc-switch-selected-hover-handle-color: @lightestGrey;
  --mdc-switch-selected-pressed-handle-color: @lightestGrey;
  --mdc-switch-selected-focus-track-color: @blue500;
  --mdc-switch-selected-hover-track-color: @blue500;
  --mdc-switch-selected-pressed-track-color: @blue500;
  --mdc-switch-selected-track-color: @blue500;
}

.mat-mdc-slide-toggle {
  & .mdc-label {
    color: @neutral800;
    font: @font-alt;
    font-size: 12px;
    font-weight: 500;
  }

  & .mdc-switch {
    & .mdc-switch__icon {
      display: none;
    }

    &.mdc-switch--selected {
      & .mdc-switch__handle-track {
        transform: translateX(80%);
      }
    }

    &.mdc-switch--unselected {
      & .mdc-switch__handle-track {
        transform: translateX(20%);
      }
    }
  }
}



.chat-unread-icon {
  height: @chat-unread-icon-font-size;
  min-width: @chat-unread-icon-font-size;
  margin-right: 5px;
  background-color: @grey;
  font-size: @chat-unread-icon-font-size;
  color: white;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  padding: 3px 3px;
  border-radius: (@chat-unread-icon-font-size + 6px) / 100%;
}

.chat-unread-count {
  position: absolute;
  transform: translate(-12px, -8px);
  font: @font-alt;
  font-size: 9px;
  line-height: 10px;
  height: 14px;
  width: 14px;
}

.tooltip {
  text-align: center;
  // background: #222;
  padding: 6px 8px;
  border-radius: 2px;
  font-size: 12px;
  line-height: 18px;
  color: #fff;

  &-multiline {
    &:extend(.tooltip);
    white-space: pre-line;
  }
}

.suggestions {
  overflow: auto;
  width: auto;
  display: flex;
  flex-direction: column;
  box-shadow: 0 0 8px @grey;
  border-radius: 10px;
  background-color: white;
  max-height: inherit;
}

.suggestions-title {
  text-align: center;
  color: @darkGrey;
  font-size: 12px;
  padding: 2px 10px;
}

.suggestion {
  padding: 5px 10px;
  border-top: 1px solid @lightestGrey;
  cursor: pointer;
  display: flex;
  flex-direction: column;

  &:hover {
    background-color: @lightestGrey;
    border-radius: 10px;
  }

  &:first-child {
    border-top: none;
  }
}

.suggestion.ai-suggestion {
  border-top: 1px solid #d0e4f3;
  background-color: #f3f8fc;

  &:hover {
    background-color: #d0e4f3;
  }
}

.suggestion-title {
  font-size: 1.1em;
  font-weight: 700;
}

.ai-suggestion .suggestion-title {
  color: @blue;
}

.suggestion-body {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.suggestion-tag {
  margin-left: 20px;
  font-size: 0.8em;
  font-weight: 700;
  font-style: italic;
}

.ai-suggestion .suggestion-tag {
  color: @blue;
}

.settings-dialog-container .mat-dialog-container {
  padding: 0;
}

.frp-emoji-img {
  object-fit: contain;
  width: 1.375em;
  height: 1.375em;
  vertical-align: bottom;
  user-select: text;
}

.mat-datepicker-content {
  overflow-y: auto;

  & .mat-calendar {
    flex: 1;
  }

  & .mat-datepicker-close-button {
    display: none;
  }
}

.mat-dialog-fullscreen {
  width: 100%;
  height: 100%;

  .mat-dialog-container {
    padding: 0;
    border-radius: 0;
  }
}

.mat-dialog-link-preview {
  .mat-dialog-container {
    padding: 0;
    border-radius: 5px;
    border: 1px solid @blue;
  }
}

.mat-dialog-emoji-selector {
  .mat-dialog-container {
    padding: 0;
    border-radius: 5px;
    border: none;
  }
}

.mat-dialog-link-preview-hidden {
  display: none;
}

.emoji {
  user-select: text;
}

.btn() {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  font-weight: 700;
  text-align: center;
  cursor: pointer;
  border-radius: 8px;
  transition: 0.2s ease-in-out;

  &:hover {
    box-shadow: 0px 4px 8px -2px rgba(0, 0, 0, 0.10), 0px 2px 4px -2px rgba(0, 0, 0, 0.10);
  }
}

.btn-lg() {
  padding: 16px 20px;

  font-size: 18px;
  line-height: 25.2px;

  &.btn-icon {
    width: 44px;
    height: 44px;
    padding: 16px;
  }

  i {
    font-size: 28px;
  }
}

.btn-md() {
  padding: 8px 12px;

  font-size: 16px;
  line-height: 24px;

  &.btn-icon {
    width: 40px;
    height: 40px;
    padding: 8px;
  }

  i {
    font-size: 24px;
  }
}

.btn-sm() {
  padding: 8px 12px;

  font-size: 14px;
  line-height: 21px;

  &.btn-icon {
    width: 36px;
    height: 36px;
    padding: 8px;
  }

  i {
    font-size: 20px;
  }
}

.btn-xs() {
  border-radius: 4px;
  padding: 4px 8px;

  font-size: 12px;
  line-height: 18px;

  &.btn-icon {
    height: 28px;
    width: 28px;
    padding: 4px;
  }

  i {
    font-size: 16px;
  }
}

.btn-primary {

  &-lg,
  &-md,
  &-sm,
  &-xs {
    .btn();
    background-color: @blue500;
    color: white;
    border: none;

    >img {
      filter: @whitefilter;
    }

    &:hover {
      background-color: @blue600;
    }

    &:active,
    &.active {
      background-color: @blue700;
    }

    &:disabled {
      background-color: @blue500;
      opacity: 0.5;

      &:hover {
        background-color: @blue500;
        box-shadow: none;
      }
    }
  }

  &-lg {
    .btn-lg();
  }

  &-md {
    .btn-md();
  }

  &-sm {
    .btn-sm();
  }

  &-xs {
    .btn-xs();
  }
}

.btn-secondary {

  &-lg,
  &-md,
  &-sm,
  &-xs {
    .btn();
    background-color: @blue25;
    color: @blue600;
    border: none;

    >img {
      filter: @blue600filter;
    }

    &:hover {
      background-color: @blue100;
    }

    &:active,
    &.active {
      background-color: @blue100;
      color: @blue700;

      >img {
        filter: @blue700filter;
      }
    }

    &:disabled {
      background-color: @blue25;
      opacity: 0.5;

      &:hover {
        background-color: @blue25;
        box-shadow: none;
      }
    }
  }

  &-lg {
    .btn-lg();
  }

  &-md {
    .btn-md();
  }

  &-sm {
    .btn-sm();
  }

  &-xs {
    .btn-xs();
  }
}

.btn-tertiary {

  &-lg,
  &-md,
  &-sm,
  &-xs {
    .btn();
    background-color: white;
    color: @blue500;
    border: 1px solid @neutral200;

    >img {
      filter: @blue500filter;
    }

    &:hover {
      background-color: @neutral50;
      color: @blue600;

      >img {
        filter: @blue600filter;
      }
    }

    &:active,
    &.active {
      background-color: white;
      color: @neutral800;
      border-color: @neutral300;

      >img {
        filter: @neutral800filter;
      }
    }

    &:disabled {
      background-color: white;
      opacity: 0.5;

      &:hover {
        background-color: white;
        color: @blue500;
        box-shadow: none;

        >img {
          filter: @blue500filter;
        }
      }
    }
  }

  &-lg {
    .btn-lg();
  }

  &-md {
    .btn-md();
  }

  &-sm {
    .btn-sm();
  }

  &-xs {
    .btn-xs();
  }
}

.btn-quaternary {

  &-lg,
  &-md,
  &-sm,
  &-xs {
    .btn();
    background-color: white;
    color: @blue500;
    border: none;

    >img {
      filter: @blue500filter;
    }

    &:hover {
      background-color: @blue500;
      color: white;

      >img {
        filter: @whitefilter;
      }
    }

    &:active,
    &.active {
      background-color: @black;
      color: white;

      >img {
        filter: @whitefilter;
      }
    }

    &:disabled {
      background-color: white;
      color: @blue500;
      opacity: 0.5;

      &:hover {
        background-color: white;
        color: @blue500;
        box-shadow: none;

        >img {
          filter: @blue500filter;
        }
      }
    }
  }

  &-lg {
    .btn-lg();
  }

  &-md {
    .btn-md();
  }

  &-sm {
    .btn-sm();
  }

  &-xs {
    .btn-xs();
  }
}

.btn-error {

  &-lg,
  &-md,
  &-sm,
  &-xs {
    .btn();
    background-color: @red500;
    color: @white;
    border: none;

    >img {
      filter: @whitefilter;
    }

    &:hover {
      background-color: @red600;
    }

    &:active,
    &.active {
      background-color: @neutral700;
    }

    &:disabled {
      background-color: white;
      color: @red500;
      opacity: 0.5;
    }
  }

  &-lg {
    .btn-lg();
  }

  &-md {
    .btn-md();
  }

  &-sm {
    .btn-sm();
  }

  &-xs {
    .btn-xs();
  }
}