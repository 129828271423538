:root {
  --accent-color: @green;
  --light-accent-color: @lighterGreen;
  --active-border-color: @neutral500;
}

@lightPink: #ff9ff3;
@pink: #f368e0;
@lightGreenBlue: #00d2d3;
@greenBlue: #01a3a4;
@yellow: #feca57;
@lightYellow: #ffebbf;
@orange: #ff9f43;
@lighterBlue: #EEF4F9;
@lightBlue: #b7d5ec;
@blue: #1376c2;
@darkBlue: #0F62A2;
@darkerBlue: #0F4B79;
@lightCyan: #48dbfb;
@cyan: #0abde3;
@lightRed: #ff6b6b;
@red: #ee5253;
@lightPurple: #A77FFF;
@purple: #570565;
@lightestGrey: #f5f5f5;
@lighterGrey: #dedede;
@lightGrey: #cccccc;
@grey: #a2a1a1;
@darkGrey: #787878;
@lighterGreen: #ecf8f1;
@lightGreen: rgb(147, 215, 177);
@green: #23854E;
@white: #fff;
@whitefilter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(0%) hue-rotate(0deg) brightness(100%) contrast(100%);
@tintColor: @lightBlue;
@black: #000;
@tabIconDefault: lightGrey;
@tabIconSelected: @tintColor;
@tabBar: #fefefe;
@errorBackground: red;
@errorText: #fff;
@warningBackground: #EAEB5E;
@warningText: #666804;
@noticeBackground: tintColor;
@noticeText: #fff;
@highlight: @lightBlue;
@accentColor: @purple;

@font: normal 14px/20px Roboto Condensed, "Inter", sans-serif;
@font-alt: normal 14px/20px "Inter", sans-serif;
@font-alt-family: "Inter", sans-serif;

@chat-unread-icon-font-size: 10px;





@green25: #EFFBF4;
@green50: #DBEFE3;
@green100: #C6E3D3;
@green200: #9DCCB2;
@green300: #75B490;
@green400: #4C9D6F;
@green500: #23854E;
@green600: #1C6A3E;
@green700: #15502F;
@green800: #0E351F;
@green900: #071B10;

// Filters are used to change the color of an svg image
@green25filter: brightness(0) saturate(100%) invert(90%) sepia(14%) saturate(132%) hue-rotate(85deg) brightness(108%) contrast(97%);
@green50filter: brightness(0) saturate(100%) invert(99%) sepia(92%) saturate(164%) hue-rotate(65deg) brightness(100%) contrast(88%);
@green100filter: brightness(0) saturate(100%) invert(94%) sepia(16%) saturate(267%) hue-rotate(87deg) brightness(97%) contrast(83%);
@green200filter: brightness(0) saturate(100%) invert(83%) sepia(25%) saturate(298%) hue-rotate(94deg) brightness(91%) contrast(87%);
@green300filter: brightness(0) saturate(100%) invert(70%) sepia(22%) saturate(512%) hue-rotate(93deg) brightness(90%) contrast(90%);
@green400filter: brightness(0) saturate(100%) invert(54%) sepia(16%) saturate(1063%) hue-rotate(93deg) brightness(97%) contrast(92%);
@green500filter: brightness(0) saturate(100%) invert(48%) sepia(68%) saturate(847%) hue-rotate(109deg) brightness(97%) contrast(86%);
@green600filter: brightness(0) saturate(100%) invert(31%) sepia(47%) saturate(619%) hue-rotate(93deg) brightness(96%) contrast(92%);
@green700filter: brightness(0) saturate(100%) invert(25%) sepia(48%) saturate(567%) hue-rotate(94deg) brightness(90%) contrast(98%);
@green800filter: brightness(0) saturate(100%) invert(15%) sepia(20%) saturate(1665%) hue-rotate(93deg) brightness(90%) contrast(91%);
@green900filter: brightness(0) saturate(100%) invert(8%) sepia(66%) saturate(405%) hue-rotate(94deg) brightness(95%) contrast(100%);

@blue25: #ECF6FD;
@blue50: #DFEDF8;
@blue100: #C9E0F2;
@blue200: #9CC5E6;
@blue300: #6EABDA;
@blue400: #4190CE;
@blue500: #1476C2;
@blue600: #105E9B;
@blue700: #0C4774;
@blue800: #082F4E;
@blue900: #041827;

@blue25filter: brightness(0) saturate(100%) invert(80%) sepia(2%) saturate(5329%) hue-rotate(195deg) brightness(122%) contrast(98%);
@blue50filter: brightness(0) saturate(100%) invert(93%) sepia(10%) saturate(297%) hue-rotate(170deg) brightness(99%) contrast(96%);
@blue100filter: brightness(0) saturate(100%) invert(84%) sepia(10%) saturate(797%) hue-rotate(181deg) brightness(107%) contrast(90%);
@blue200filter: brightness(0) saturate(100%) invert(86%) sepia(9%) saturate(1793%) hue-rotate(178deg) brightness(84%) contrast(119%);
@blue300filter: brightness(0) saturate(100%) invert(70%) sepia(14%) saturate(1458%) hue-rotate(168deg) brightness(92%) contrast(85%);
@blue400filter: brightness(0) saturate(100%) invert(57%) sepia(25%) saturate(1311%) hue-rotate(167deg) brightness(87%) contrast(83%);
@blue500filter: brightness(0) saturate(100%) invert(31%) sepia(89%) saturate(997%) hue-rotate(179deg) brightness(99%) contrast(93%);
@blue600filter: brightness(0) saturate(100%) invert(18%) sepia(100%) saturate(2477%) hue-rotate(192deg) brightness(90%) contrast(87%);
@blue700filter: brightness(0) saturate(100%) invert(23%) sepia(9%) saturate(6073%) hue-rotate(172deg) brightness(97%) contrast(99%);
@blue800filter: brightness(0) saturate(100%) invert(14%) sepia(15%) saturate(5193%) hue-rotate(179deg) brightness(95%) contrast(97%);
@blue900filter: brightness(0) saturate(100%) invert(9%) sepia(12%) saturate(3707%) hue-rotate(166deg) brightness(94%) contrast(101%);

@gold25: #FEFAF0;
@gold50: #FDF4DB;
@gold100: #FCEDC7;
@gold200: #FAE09E;
@gold300: #F9D475;
@gold400: #F7C74C;
@gold500: #F5BA24;
@gold600: #D19E1D;
@gold700: #AC8216;
@gold800: #886610;
@gold900: #634A09;

@gold25filter: brightness(0) saturate(100%) invert(100%) sepia(18%) saturate(1998%) hue-rotate(300deg) brightness(105%) contrast(99%);
@gold50filter: brightness(0) saturate(100%) invert(86%) sepia(15%) saturate(479%) hue-rotate(336deg) brightness(112%) contrast(98%);
@gold100filter: brightness(0) saturate(100%) invert(93%) sepia(13%) saturate(845%) hue-rotate(326deg) brightness(106%) contrast(98%);
@gold200filter: brightness(0) saturate(100%) invert(86%) sepia(40%) saturate(390%) hue-rotate(340deg) brightness(102%) contrast(96%);
@gold300filter: brightness(0) saturate(100%) invert(78%) sepia(55%) saturate(374%) hue-rotate(352deg) brightness(101%) contrast(95%);
@gold400filter: brightness(0) saturate(100%) invert(95%) sepia(49%) saturate(3651%) hue-rotate(318deg) brightness(101%) contrast(94%);
@gold500filter: brightness(0) saturate(100%) invert(83%) sepia(14%) saturate(6024%) hue-rotate(343deg) brightness(108%) contrast(92%);
@gold600filter: brightness(0) saturate(100%) invert(56%) sepia(61%) saturate(563%) hue-rotate(5deg) brightness(101%) contrast(91%);
@gold700filter: brightness(0) saturate(100%) invert(50%) sepia(88%) saturate(1166%) hue-rotate(16deg) brightness(86%) contrast(83%);
@gold800filter: brightness(0) saturate(100%) invert(35%) sepia(14%) saturate(6798%) hue-rotate(28deg) brightness(96%) contrast(87%);
@gold900filter: brightness(0) saturate(100%) invert(25%) sepia(24%) saturate(3989%) hue-rotate(29deg) brightness(91%) contrast(93%);

@red25: #FEF7F6;
@red50: #FBE3E0;
@red100: #F8CFCB;
@red200: #F2A7A0;
@red300: #EC7F75;
@red400: #E6574A;
@red500: #E02F1F;
@red600: #B52619;
@red700: #8A1D13;
@red800: #5F140D;
@red900: #340B07;

@red25filter: brightness(0) saturate(100%) invert(87%) sepia(4%) saturate(554%) hue-rotate(315deg) brightness(112%) contrast(99%);
@red50filter: brightness(0) saturate(100%) invert(87%) sepia(2%) saturate(2034%) hue-rotate(316deg) brightness(107%) contrast(97%);
@red100filter: brightness(0) saturate(100%) invert(83%) sepia(3%) saturate(2214%) hue-rotate(315deg) brightness(105%) contrast(94%);
@red200filter: brightness(0) saturate(100%) invert(66%) sepia(62%) saturate(245%) hue-rotate(316deg) brightness(99%) contrast(92%);
@red300filter: brightness(0) saturate(100%) invert(61%) sepia(24%) saturate(1146%) hue-rotate(315deg) brightness(97%) contrast(91%);
@red400filter: brightness(0) saturate(100%) invert(46%) sepia(61%) saturate(3743%) hue-rotate(335deg) brightness(98%) contrast(84%);
@red500filter: brightness(0) saturate(100%) invert(31%) sepia(47%) saturate(3645%) hue-rotate(345deg) brightness(86%) contrast(105%);
@red600filter: brightness(0) saturate(100%) invert(20%) sepia(74%) saturate(3960%) hue-rotate(355deg) brightness(74%) contrast(89%);
@red700filter: brightness(0) saturate(100%) invert(12%) sepia(94%) saturate(2832%) hue-rotate(353deg) brightness(95%) contrast(93%);
@red800filter: brightness(0) saturate(100%) invert(4%) sepia(63%) saturate(7336%) hue-rotate(4deg) brightness(120%) contrast(90%);
@red900filter: brightness(0) saturate(100%) invert(4%) sepia(62%) saturate(4479%) hue-rotate(356deg) brightness(103%) contrast(96%);

@teal25: #F3FDFB;
@teal50: #DBFAF4;
@teal100: #B7F6E9;
@teal200: #94F1DE;
@teal300: #64EBCF;
@teal400: #34E4C1;
@teal500: #1CD4AF;
@teal600: #17AC8E;
@teal700: #11856D;
@teal800: #0C5D4D;
@teal900: #031B16;

@teal25filter: brightness(0) saturate(100%) invert(89%) sepia(4%) saturate(717%) hue-rotate(86deg) brightness(116%) contrast(98%);
@teal50filter: brightness(0) saturate(100%) invert(94%) sepia(6%) saturate(563%) hue-rotate(114deg) brightness(103%) contrast(96%);
@teal100filter: brightness(0) saturate(100%) invert(93%) sepia(5%) saturate(1626%) hue-rotate(110deg) brightness(99%) contrast(96%);
@teal200filter: brightness(0) saturate(100%) invert(90%) sepia(19%) saturate(688%) hue-rotate(106deg) brightness(99%) contrast(90%);
@teal300filter: brightness(0) saturate(100%) invert(81%) sepia(29%) saturate(678%) hue-rotate(112deg) brightness(99%) contrast(90%);
@teal400filter: brightness(0) saturate(100%) invert(74%) sepia(84%) saturate(399%) hue-rotate(104deg) brightness(96%) contrast(86%);
@teal500filter: brightness(0) saturate(100%) invert(90%) sepia(56%) saturate(2918%) hue-rotate(87deg) brightness(92%) contrast(79%);
@teal600filter: brightness(0) saturate(100%) invert(44%) sepia(99%) saturate(353%) hue-rotate(118deg) brightness(103%) contrast(93%);
@teal700filter: brightness(0) saturate(100%) invert(36%) sepia(75%) saturate(473%) hue-rotate(118deg) brightness(97%) contrast(91%);
@teal800filter: brightness(0) saturate(100%) invert(27%) sepia(25%) saturate(1304%) hue-rotate(118deg) brightness(98%) contrast(92%);
@teal900filter: brightness(0) saturate(100%) invert(7%) sepia(9%) saturate(6947%) hue-rotate(130deg) brightness(97%) contrast(98%);

@magenta25: #FEEEF5;
@magenta50: #FDCCE0;
@magenta100: #FCABCB;
@magenta200: #F978AC;
@magenta300: #F7458D;
@magenta400: #F62478;
@magenta500: #DC095E;
@magenta600: #B0074B;
@magenta700: #840538;
@magenta800: #580426;
@magenta900: #2C0213;

@magenta25filter: brightness(0) saturate(100%) invert(100%) sepia(33%) saturate(1874%) hue-rotate(286deg) brightness(106%) contrast(99%);
@magenta50filter: brightness(0) saturate(100%) invert(76%) sepia(30%) saturate(346%) hue-rotate(296deg) brightness(109%) contrast(98%);
@magenta100filter: brightness(0) saturate(100%) invert(72%) sepia(28%) saturate(619%) hue-rotate(295deg) brightness(105%) contrast(98%);
@magenta200filter: brightness(0) saturate(100%) invert(65%) sepia(31%) saturate(1811%) hue-rotate(297deg) brightness(103%) contrast(95%);
@magenta300filter: brightness(0) saturate(100%) invert(53%) sepia(58%) saturate(6348%) hue-rotate(312deg) brightness(101%) contrast(94%);
@magenta400filter: brightness(0) saturate(100%) invert(26%) sepia(40%) saturate(6615%) hue-rotate(322deg) brightness(98%) contrast(96%);
@magenta500filter: brightness(0) saturate(100%) invert(17%) sepia(86%) saturate(6917%) hue-rotate(329deg) brightness(87%) contrast(97%);
@magenta600filter: brightness(0) saturate(100%) invert(8%) sepia(85%) saturate(6167%) hue-rotate(329deg) brightness(97%) contrast(96%);
@magenta700filter: brightness(0) saturate(100%) invert(10%) sepia(62%) saturate(5426%) hue-rotate(325deg) brightness(90%) contrast(103%);
@magenta800filter: brightness(0) saturate(100%) invert(9%) sepia(35%) saturate(5343%) hue-rotate(316deg) brightness(99%) contrast(108%);
@magenta900filter: brightness(0) saturate(100%) invert(3%) sepia(42%) saturate(6875%) hue-rotate(324deg) brightness(103%) contrast(101%);

@burgundy25: #F8F4F7;
@burgundy50: #F2E9EF;
@burgundy100: #E5D2DF;
@burgundy200: #CBA5BF;
@burgundy300: #B1799F;
@burgundy400: #974C7F;
@burgundy500: #7D1F5F;
@burgundy600: #6A1A50;
@burgundy700: #561542;
@burgundy800: #431033;
@burgundy900: #300A24;

@burgundy25filter: brightness(0) saturate(100%) invert(90%) sepia(2%) saturate(276%) hue-rotate(265deg) brightness(108%) contrast(96%);
@burgundy50filter: brightness(0) saturate(100%) invert(95%) sepia(7%) saturate(159%) hue-rotate(267deg) brightness(97%) contrast(98%);
@burgundy100filter: brightness(0) saturate(100%) invert(96%) sepia(8%) saturate(841%) hue-rotate(279deg) brightness(95%) contrast(89%);
@burgundy200filter: brightness(0) saturate(100%) invert(74%) sepia(27%) saturate(265%) hue-rotate(266deg) brightness(93%) contrast(83%);
@burgundy300filter: brightness(0) saturate(100%) invert(60%) sepia(16%) saturate(875%) hue-rotate(267deg) brightness(86%) contrast(85%);
@burgundy400filter: brightness(0) saturate(100%) invert(40%) sepia(10%) saturate(2757%) hue-rotate(266deg) brightness(88%) contrast(87%);
@burgundy500filter: brightness(0) saturate(100%) invert(16%) sepia(24%) saturate(6289%) hue-rotate(295deg) brightness(96%) contrast(92%);
@burgundy600filter: brightness(0) saturate(100%) invert(14%) sepia(21%) saturate(6987%) hue-rotate(296deg) brightness(91%) contrast(94%);
@burgundy700filter: brightness(0) saturate(100%) invert(11%) sepia(26%) saturate(5845%) hue-rotate(295deg) brightness(93%) contrast(95%);
@burgundy800filter: brightness(0) saturate(100%) invert(11%) sepia(25%) saturate(4417%) hue-rotate(289deg) brightness(86%) contrast(99%);
@burgundy900filter: brightness(0) saturate(100%) invert(6%) sepia(44%) saturate(3417%) hue-rotate(295deg) brightness(92%) contrast(99%);

@neutral25: #F8F9F9;
@neutral50: #ECEEEF;
@neutral100: #DEE0E2;
@neutral200: #C1C6CA;
@neutral300: #A5ACB1;
@neutral400: #889199;
@neutral500: #6C7780;
@neutral600: #565F66;
@neutral700: #41474D;
@neutral800: #2B3033;
@neutral900: #16181A;

@neutral25filter: brightness(0) saturate(100%) invert(100%) sepia(66%) saturate(691%) hue-rotate(156deg) brightness(116%) contrast(95%);
@neutral50filter: brightness(0) saturate(100%) invert(89%) sepia(7%) saturate(45%) hue-rotate(155deg) brightness(108%) contrast(91%);
@neutral100filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(2243%) hue-rotate(175deg) brightness(91%) contrast(93%);
@neutral200filter: brightness(0) saturate(100%) invert(90%) sepia(7%) saturate(158%) hue-rotate(164deg) brightness(90%) contrast(87%);
@neutral300filter: brightness(0) saturate(100%) invert(77%) sepia(9%) saturate(205%) hue-rotate(162deg) brightness(89%) contrast(87%);
@neutral400filter: brightness(0) saturate(100%) invert(68%) sepia(5%) saturate(629%) hue-rotate(167deg) brightness(84%) contrast(87%);
@neutral500filter: brightness(0) saturate(100%) invert(50%) sepia(41%) saturate(109%) hue-rotate(165deg) brightness(85%) contrast(94%);
@neutral600filter: brightness(0) saturate(100%) invert(37%) sepia(6%) saturate(836%) hue-rotate(164deg) brightness(92%) contrast(87%);
@neutral700filter: brightness(0) saturate(100%) invert(27%) sepia(3%) saturate(1519%) hue-rotate(169deg) brightness(91%) contrast(88%);
@neutral800filter: brightness(0) saturate(100%) invert(14%) sepia(10%) saturate(731%) hue-rotate(159deg) brightness(89%) contrast(84%);
@neutral900filter: brightness(0) saturate(100%) invert(6%) sepia(9%) saturate(806%) hue-rotate(169deg) brightness(95%) contrast(92%);

@gray300: #D0D5DD;
@gray500: #667085;
@gray700: #344054;
@gray900: #101828;

@black700: #41474D;
@black900: #16181A;